import $ from 'jquery';
import dataLayer from '../utils/data-layer';
import {sha256} from '../utils/crypto';

export default {
  init() {
    console.log('init');
    this.initialTracking();
    this.addEvents();
  },

  initialTracking() {
    if (window.location.href.includes('loginError')) {
      dataLayer.push({
        event: 'loginError'
      });
    }
    if (window.location.href.includes('loginSuccess')) {
      dataLayer.push({
        event: 'loginSuccess'
      });

      dataLayer.push({
        engagement: {
          component: {
            name: 'Cognito login',
            position: 'main content',
            type: 'Cognito login',
          },
          type: 'form success'
        },
        event: 'login success',
        eventParams: {
          category: 'login',
        },
      });
      dataLayer.push({
        engagement: undefined,
        eventParams: undefined
      });
    }
    if (window.location.href.includes('updateProfileSuccess')) {
      dataLayer.push({
        event: 'updateProfileFinished'
      });
    }
  },

  addEvents() {
    $(document).on('submit', '.login-form', this.eventHandlers.onLogin);
    $(document).on('click', '.logout-link', this.eventHandlers.onLogout);
    $(document).on('click', '.forgot-login-submit', this.eventHandlers.onForgotLoginClick);
    window.jQuery(document).on('searchwp_live_search_success', this.eventHandlers.onSearch);
    window.addEventListener('premium-search-result', this.eventHandlers.onPremiumSearch);
    $(document).on('click', '#premium-results a', this.eventHandlers.onSearchPremiumItemClick);
    $(document).on('click', '.searchwp-live-search-result a', this.eventHandlers.onSearchItemClick);
    $(document).on('click', '.js-widget-click', this.eventHandlers.onWidgetClick);
    $(document).on('click', '.gtm-event-track', this.eventHandlers.onDefaultGtmEventTrackClick);
    $(document).on('submit', '.contact-form', this.eventHandlers.onContactFormSubmitted);
    $(document).on('click', "a[href^='tel']", this.eventHandlers.onPhoneNumberClicked);
    $(document).on('click', '.faq-item', this.eventHandlers.onFaqItemClick);
    $(document).on('click', '.faq-item a', this.eventHandlers.onFaqItemLinkClick);
    $(document).on('click', '[download]', this.eventHandlers.onDownloadClick);
    $(document).on('click', '.sidebar-menu--pages a, .header-menu--pages a', this.eventHandlers.onMenuPagesClick);
    window.jQuery(document).on('print-page', this.eventHandlers.onPrintPageClick);
    $(document).on('click', '.js--cancel-btn', this.eventHandlers.onCancelClick);

    // New tracking
    $(document).on('click', '.faq-item', this.eventHandlers.trackAccordionInteraction);
    $(document).on('click', '.subscription-select, .subscription-selector__item', this.eventHandlers.trackSubscriptionSelect);
    $(document).on('click', '.js--interaction', this.eventHandlers.trackInteraction);
    $(document).on('click', '.swiper-navigation .btn', this.eventHandlers.trackSwipeInteraction);
    $(document).on('click', '.js--change-card', this.eventHandlers.trackChangePayment);
    $(document).on('click', '.js--change-payment', this.eventHandlers.trackChangePayment);
    $(document).on('click', '.js--hambuger-toggle', this.eventHandlers.trackBurgerToggle);
    $(document).on('click', '.js--sticky-notice', this.eventHandlers.trackStickyNotice);
    $(document).on('click', '.gtm-interaction-track', this.eventHandlers.trackGenericInteraction);
    $(document).on('click', 'a:not(.js--no-click)', this.eventHandlers.onLinkClick);
  },

  eventHandlers: {
    trackGenericInteraction()
    {
      const $el = $(this);
      const data = $el.data('gtm-interaction');
      dataLayer.sendInteraction(data.event, data.eventParams, data.type, { name: $el.attr('class'), type: $el.attr('class')});
    },
    trackStickyNotice()
    {
      const $el = $(this);
      const event = 'interaction close';
      const type = 'close';
      const text = 'StickyNotice_close';
      dataLayer.sendInteraction(event, { clickedText: text }, type, { name: $el.attr('class'), type: $el.attr('class')});
    },
    trackBurgerToggle()
    {
      const $el = $(this);
      const event = $el.find('i').hasClass('fa-times') ? 'interaction close' : 'interaction open';
      const type = event === 'interaction open' ? 'open' : 'close';
      const text = $el.text().trim();
      dataLayer.sendInteraction(event, { clickedText: text }, type, { name: $el.attr('class'), type: $el.attr('class')});
    },
    trackChangePayment()
    {
      const $el = $(this);
      const event = $el.hasClass('btn') ? 'interaction open' : 'interaction close';
      const type = $el.hasClass('btn') ? 'open' : 'close';
      const text = $el.text().trim();
      dataLayer.sendInteraction(event, {clickedText: text}, type, {name: $el.attr('class'), type: $el.attr('class')});
    },
    trackSwipeInteraction() {
      const $el = $(this);
      dataLayer.sendInteraction('interaction swipe', undefined, 'swipe', {
        name: $el.attr('class'),
        type: $el.attr('class')
      });
    },
    trackInteraction() {
      const $el = $(this);
      const text = $el.find('span').text().trim();
      dataLayer.sendInteraction('interaction expand', {clickedText: text}, 'expand', {
        name: $el.attr('class'),
        type: $el.attr('class')
      });
    },
    trackSubscriptionSelect() {
      const $el = $(this);
      const $section = $el.parents('.js--component').first();
      dataLayer.sendInteraction('interaction toggle', undefined, 'toggle', {
        name: $section.attr('class'),
        type: $section.attr('class')
      });
    },
    trackAccordionInteraction() {
      setTimeout(() => {
        const $el = $(this);
        const event = $el.hasClass('expanded') ? 'interaction expand' : 'interaction collapse';
        const type = $el.hasClass('expanded') ? 'expand' : 'collapse';
        const text = $el.find('.faq-title').text().trim();
        dataLayer.sendInteraction(event, {clickedText: text}, type, {name: $el.attr('class'), type: $el.attr('class')});
      }, 200);

    },
    onLinkClick() {
      const $link = $(this);

      if ($link.attr('href') === '#') {
        return;
      }
      const currentUrl = new URL(window.location.href);
      const urlObj = new URL($link.attr('href'));

      // Dont send link clicks if only query params changes
      if (currentUrl.hostname === urlObj.hostname && currentUrl.pathname === urlObj.pathname) {
        return;
      }

      const event = {
        event: 'link click',
        engagement: {
          type: 'link click',
          component: {
            name: $link.attr('class'),
            type: $link.attr('class'),
          },
          destination: {
            url: $link.attr('href'),
            title: document.title,
            type: window.location.hostname === urlObj.hostname ? 'internal' : 'external',
          },
        },
      };

      console.info(event);
      window.dataLayer.push(event);
      window.dataLayer.push({
        engagement: undefined
      });
    },
    onCancelClick(e) {
      const $target = $(e.target);

      window.dataLayer.push({
        'event': 'widgetClick',
        'widgetDestinationTitle': 'subscription-cancel-main-cta',
        'widgetPlacement': 'ksw-subscription-detail-page'
      });

      if ($target.hasClass('js--maybe-ajax-modal')) {
        window.dataLayer.push({
          'event': 'popUpVisible',
          'popUpName': window.location.protocol + '//' + window.location.hostname + window.location.pathname,
          'widgetPlacement': 'ksw-subscription-detail-page',
        });
      }
    },
    onPrintPageClick() {
      // push to datalayer..
    },
    onDefaultGtmEventTrackClick() {
      setTimeout(() => {
        dataLayer.push({
          event: $(this).data('gtm-event')
        });
      });
    },
    onLogin() {
      dataLayer.push({
        event: 'loginSubmit'
      });

      dataLayer.push({
        engagement: {
          component: {
            name: "Cognito login",
            position: "main content",
            type: "Cognito login"
          },
          type: "form submit"
        },
        event: "login submit",
        eventParams: {
          category: "login",
        },
      });
      dataLayer.push({
        engagement: undefined,
        eventParams: undefined
      });
    },
    onLogout() {
      dataLayer.push({
        event: 'logoutSuccess'
      });
    },
    async onForgotLoginClick() {
      const hashed = await sha256($('.forgot-login-email').val());
      dataLayer.push({
        event: 'loginPasswordForgotten',
        userId: hashed
      });
    },
    onPremiumSearch(e) {
      setTimeout(() => {
        dataLayer.push({
          event: 'internalSearch',
          searchKeyword: e.detail.search,
          searchResultNumber: e.detail.hits,
          searchRefined: false
        });

        // New tracking
        dataLayer.push({
          engagement: {
            component: {
              name: "search",
              position: "main content"
            }
          },
          type: "search",
          event: "search submit",
          eventParams: {
            category: "search",
            searchQuery:  e.detail.search,
          },
        });
        dataLayer.push({
          engagement: undefined,
          eventParams: undefined,
        });

       }, 100);
    },
    onSearch(e, input) {
      setTimeout(() => {
        dataLayer.push({
          event: 'internalSearch',
          searchKeyword: $(input[0]).val(),
          searchResultNumber: $('.searchwp-live-search-result').length,
          searchRefined: false
        });

        // New tracking
        dataLayer.push({
          engagement: {
            component: {
              name: "search",
              position: "header"
            }
          },
          type: "search",
          event: "search submit",
          eventParams: {
            category: "search",
            searchQuery:  $(input[0]).val(),
          },
        });
        dataLayer.push({
          engagement: undefined,
          eventParams: undefined,
        });

      }, 100);
    },
    onSearchItemClick() {
      dataLayer.push({
        event: 'internalSearchResultClick',
        searchResultLink: $(this).attr('href'),
        searchResultPosition: $(this).closest('.searchwp-live-search-result').index() + 1,
        searchResultPage: 1
      });

      dataLayer.push({
        engagement: {
          component: {
            index: '' + $(this).closest('.searchwp-live-search-result').index(),
            name: $.trim($(this).text()),
            range: $('.searchwp-live-search-result').length,
            type: "link click"
          },
          destination: {
            title: $.trim($(this).text()),
            type: "internal",
            url: $(this).attr('href'),
          }
        },
        type: "link click",
        event: "search result link click",
        eventParams: {
          category: "search",
          searchQuery: $('#site-search').val()
        },
      });

      dataLayer.push({
        engagement: undefined,
        eventParams: undefined,
      });
    },
    onSearchPremiumItemClick() {
      dataLayer.push({
        event: 'internalSearchResultClick',
        searchResultLink: $(this).attr('href'),
        searchResultPosition: $('#premium-results').find('.premium-item:visible').toArray().findIndex((item) => {
          return $(item).data('id') === $(this).closest('.premium-item').data('id')
        }) + 1,
        searchResultPage: 1
      });

      dataLayer.push({
        engagement: {
          component: {
            index: '' + $('.premium-item:visible').index($(this).closest('.premium-item')),
            name: $.trim($(this).find('span').text()),
            range: $('.premium-item:visible').length,
            type: "link click"
          },
          destination: {
            title: $.trim($(this).find('span').text()),
            type: "internal",
            url: $(this).attr('href'),
          }
        },
        type: "link click",
        event: "search result link click",
        eventParams: {
          category: "search",
          searchQuery: $('#premium-search').val()
        },
      });

      dataLayer.push({
        engagement: undefined,
        eventParams: undefined,
      });
    },
    onWidgetClick() {
      const $elem = $(this);
      const href = $elem.attr('href');
      dataLayer.push({
        event: 'widgetClick',
        widgetPlacement: $elem.data('widget-placement'),
        widgetDestinationTitle: $elem.data('widget-title'),
        widgetDestinationUrl: href && href.length ? href : '[undefined]',
      });
    },
    onMenuPagesClick() {
      const $elem = $(this);
      dataLayer.push({
        event: 'widgetClick',
        widgetPlacement: "Menu_Page_" + $elem.text(),
        widgetDestinationTitle: $elem.text(),
        widgetDestinationUrl: $elem.attr('href'),
      });
    },
    onContactFormSubmitted() {
      dataLayer.push({
        event: 'contactFormSubmitted',
        contactFormCategory: $('#category option:selected').text()
      });
    },
    onPhoneNumberClicked() {
      dataLayer.push({
        event: 'contactPhonenumberClicked'
      })
    },
    onFaqItemClick() {

      const $elem = $(this);
      if ($elem.find('.content').is(':visible')) {
        return;
      }
      if (!$elem.hasClass('expanded')) {
        dataLayer.push({
          event: 'faqExpand',
          faqVars: {
            faqQuestion: $elem.find('.faq-title').text(),
            faqCategory: $elem.data('category')
          }
        });
      }
      $elem.toggleClass('expanded');
    },
    onFaqItemLinkClick() {
      const $elem = $(this);
      dataLayer.push({
        event: 'faqClick',
        faqVars: {
          faqQuestion: $elem.closest('.faq-item').find('.faq-title').text(),
          faqCategory: $elem.closest('.faq-item').data('category'),
          faqDestinationUrl: $elem.attr('href'),
          faqDestinationTitle: $elem.text().trim(),
        }
      });
    },
    onDownloadClick() {
      const hrefArr = $(this).attr('href').split('.');
      dataLayer.push({
        event: 'clickToDownload',
        downloadType: hrefArr.pop(),
        downloadTitle: hrefArr.pop().split('/').pop()
      });
    }
  }
}
